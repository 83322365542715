<template>
  <div :class="containerClasses" :style="containerStyles">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { ISharedContainerProps } from './SharedContainer.types';

const COMPONENT_NAME = 'shared-container';

const props = withDefaults(defineProps<ISharedContainerProps>(), {
  md: false,
  sm: false,
  xl: false,
  xxl: false,
});

const containerClasses = computed(() => ({
  [COMPONENT_NAME]: true,
  [`${COMPONENT_NAME}--md`]: props.md,
  [`${COMPONENT_NAME}--sm`]: props.sm,
  [`${COMPONENT_NAME}--xl`]: props.xl,
  [`${COMPONENT_NAME}--xxl`]: props.xxl,
}));

const containerStyles = computed(() => {
  const properties: CSSProperties = {};

  if (props.p) Object.assign(properties, GlobalUtils.CSS.handleBlockSpacing('padding', props.p));
  if (props.m) Object.assign(properties, GlobalUtils.CSS.handleBlockSpacing('margin', props.m));

  return properties;
});
</script>

<style src="./SharedContainer.scss" scoped lang="scss"></style>
